import React from 'react';
import GridContainer from '../layout/Grid/GridContainer.js';
import GridRow from '../layout/Grid/GridRow';
import GridColumn from '../layout/Grid/GridColumn';
import Section from '../layout/Section.js';
import Button from '../components/Button.js';
import SEO from '../components/SEO.js';

export default function SuccessPage() {
  return (
    <>
      <SEO title="Vielen Dank – Ihre Nachricht ist bei uns eingegangen." />
      <Section>
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="8">
              <h1>Vielen Dank – Ihre Nachricht ist bei uns eingegangen.</h1>
              {/* <p>Wir melden uns</p>  */}
              <Button to="../">Zur Startseite</Button>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>
    </>
  );
}
